import React from 'react' ;
import { Box, Typography, useTheme, useMediaQuery, Collapse, Alert, TextField, Divider, Button } from '@mui/material';
import { useState } from 'react';
import {Link} from 'react-router-dom';
import axios from 'axios';
import configuration from '../../config.json';

const ForgotPasswordScreen = () => { 
    const theme = useTheme();
    const backendUrl = configuration.backendUrl;
    const isNotMobile = useMediaQuery("(min-width: 1000px)");

    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const forgotPasswordHandler = async (e) => {
        e.preventDefault();

        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        try {
            const { data } = await axios.post(
                backendUrl+"/api/auth/forgot-password",
                { email },
                config
            );

            setSuccess(data.data);
        } catch (error) {
            if (error?.response?.data?.error) {
                setError(error.response.data.error);
            } else if (error.message) {
                setError(error.message);
            } else {
                setError("Something went wrong. Please try again later.");
            }
        }
    };

    //useEffect for error and success
    React.useEffect(() => {
        if (error.length > 0) {
            //scroll to top of page
            window.scrollTo(0, 0);
            setTimeout(() => {
                setEmail("");
                setError("");
            }, 5000);
        }
        if (success.length > 0) {
            setTimeout(() => {
                setSuccess("");
            }, 5000);
        }
    }, [error, success]);

    return (
        <Box width={isNotMobile ? "35%" : "80%" } 
            p="3rem" 
            m="2rem auto" 
            my={isNotMobile ? 10 : 8}
            borderRadius={5} 
            bgcolor={theme.palette.background.paper} 
            sx={{boxShadow:5}}
        >
            <Collapse in={error}>
                <Alert severity="error" sx={{mb:2}}>{error}</Alert>
            </Collapse>
            <Collapse in={success}>
                <Alert severity="success" sx={{mb:2}}>{success}</Alert>
            </Collapse>
            <form onSubmit={forgotPasswordHandler}>
                <Typography variant="h3" fontWeight="bold" mb={3} textAlign="center" color="primary">Forgot Password</Typography>

                <Typography variant="h6" fontWeight={500} mb={1}>Email</Typography>
                <TextField sx={{".MuiOutlinedInput-root": { borderRadius: 1 }, mb:2}} required fullWidth value={email} onChange={(e) => setEmail(e.target.value)}/>

                <Button type="submit" variant="contained" fullWidth size="large" sx={{py: 1.3}}>Send Email</Button>

                <Divider sx={{my:4}}></Divider>
            </form>
            <Typography mt={2} textAlign="center">Don't have an account? <Link to="/signup" style={{color: theme.palette.primary.main}}><b>Sign up</b></Link></Typography>
        </Box>
    )
}

export default ForgotPasswordScreen;