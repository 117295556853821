import React, { useState, useMemo, useEffect } from 'react';
import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider, Box, useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { themeSettings } from './theme';
import Navbar from "./components/common/Navbar.js";
import Footer from "./components/common/Footer.js";
import HomeScreen from "./components/screens/HomeScreen";
import ErrorScreen from "./components/screens/ErrorScreen";
import AboutScreen from "./components/screens/About.js";
import Pricing from "./components/screens/Pricing.js";
import FaqScreen from "./components/screens/Faq";
import SearchScreen from './components/screens/Search.js';
import SummaryScreen from './components/screens/Summary.js';
import RegisterScreen from './components/screens/Register.js';
import LoginScreen from './components/screens/Login.js';
import Flashcards from './components/screens/Flashcards.js';
import BookmarksScreen from './components/screens/BookmarksScreen.js';
import ProfileScreen from './components/screens/ProfileScreen.js';
import ScrollToTop from './ScrollToTop';
import io from 'socket.io-client';
import { SocketContext } from './SocketContext';
import PracticeQuiz from './components/screens/Quiz.js';
import ForgotPasswordScreen from './components/screens/ForgotPassword.js';
import ResetPasswordScreen from './components/screens/ResetPassword.js';
import VerifyEmailScreen from './components/screens/VerifyEmail.js';

const App = () => {
    const [socket, setSocket] = useState(null);
    const [themeMode, setThemeMode] = useState('light');
    const mobile = useMediaQuery('(max-width: 600px)');

    const toggleTheme = () => {
        setThemeMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
    };

    const theme = useMemo(() => createTheme(themeSettings(themeMode)), [themeMode]);

    useEffect(() => {
        if (localStorage.getItem('mode')) {
          setThemeMode(localStorage.getItem('mode'));
        }
        const newSocket = io('http://localhost:8080');//io('https://ezread.io');//
        setSocket(newSocket);
        // Clean up the effect
        return () => newSocket.disconnect();
      }, []);

    return (
        socket && <SocketContext.Provider value={socket}>
            <div className="app">
                <ThemeProvider theme={theme}>
                <CssBaseline />
                    <Navbar />
                    <ScrollToTop />
                    <Box sx={{ paddingTop: mobile ? '108px' : '62px' }} >
                        <Routes>
                            <Route exact path="/" element={<HomeScreen />} />
                            <Route exact path="/signup" element={<RegisterScreen />} />
                            <Route exact path="/login" element={<LoginScreen />} />
                            <Route exact path="/about" element={<AboutScreen />} />
                            <Route exact path="/faq" element={<FaqScreen />} />
                            <Route exact path="/pricing" element={<Pricing />} />
                            <Route exact path="/search/:query" element={<SearchScreen />} />
                            <Route exact path="/summary/:chapterId" element={<SummaryScreen />} />
                            <Route exact path="/ai/flashcards" element={<Flashcards />} />
                            <Route exact path="/ai/quiz" element={<PracticeQuiz />} />
                            <Route exact path="/bookmarks" element={<BookmarksScreen />} />
                            <Route exact path="/profile" element={<ProfileScreen />} />
                            <Route exact path="/forgot-password" element={<ForgotPasswordScreen />} />
                            <Route exact path="/passwordreset/:resetToken" element={<ResetPasswordScreen/>} />
                            <Route exact path="/verify-email/:verifyToken" element={<VerifyEmailScreen/>} />
                            <Route path="*" element={<ErrorScreen />} />
                        </Routes>
                    </Box>
                    <Footer />
                </ThemeProvider>
            </div>
        </SocketContext.Provider>
    );
};

export default App;
