import * as React from 'react';
import {
    Divider,
    Box,
    Stack,
    Typography,
    Pagination,
    Select,
    ImageList,
    useMediaQuery,
    FormControl,
    MenuItem,
    InputLabel,
    Button
} from "@mui/material";
import { useParams, useNavigate, Link } from "react-router-dom";
import axios from 'axios';
import config from '../../config.json';
import TextbookCard from '../common/TextbookCard';
import LoadingSpinner from '../common/LoadingSpinner';

const SearchScreen = () => {
    const params = useParams();
    const navigate = useNavigate();
    const backendUrl = config.backendUrl;
    const [query, setQuery] = React.useState('');
    const [subject, setSubject] = React.useState('');
    const [publisher, setPublisher] = React.useState('');
    const [edition, setEdition] = React.useState('');
    const [year, setYear] = React.useState('');
    const [page, setPage] = React.useState(1);
    const [sortBy, setSortBy] = React.useState('Default');
    const [textbooks, setTextbooks] = React.useState([]);
    const [pageCount, setPageCount] = React.useState(1);
    const [loading, setLoading] = React.useState(true);
    const scrollRef = React.useRef(null);

    const mobile = useMediaQuery("(max-width: 900px)");
    const tablet = useMediaQuery("(max-width: 1300px)");
    const columns = mobile ? 1 : tablet ? 2 : 3;

    const handleSortBy = (event) => {
        setSortBy(event.target.value);
        navigate(`/search/query=${query}&subject=${subject}&publisher=${publisher}&edition=${edition}&year=${year}&page=${page}&sortBy=${event.target.value}`);
    };

    //useEffect for fetching data
    React.useEffect(() => {
       // Ensure params.query is a string
        const queryString = typeof params?.query === 'string' ? params.query : '';
        
        // Split params.query into query, subject, publisher, edition, year, page, sortBy
        const queryArray = queryString.split('&');

        
        queryArray.forEach(param => {
            const [key, value] = param.split('=');
            switch (key) {
                case 'query':
                    setQuery(value);
                    break;
                case 'subject':
                    setSubject(value);
                    break;
                case 'publisher':
                    setPublisher(value);
                    break;
                case 'edition':
                    setEdition(value);
                    break;
                case 'year':
                    setYear(value);
                    break;
                case 'page':
                    setPage(parseInt(value));
                    break;
                case 'sortBy':
                    setSortBy(value);
                    break;
                default:
                    break;
            }
        });
        //call axios to get data
        axios.get(backendUrl+`/api/textbooks/search?${params.query}`)
            .then(res => { setTextbooks(res.data.results); setPageCount(res.data.pageCount); setLoading(false);})
            .catch(err => console.log(err));
    }, [params]);

    const handlePageChange = (event, newPage)  => {
        setPage(newPage);
        navigate(`/search/query=${query}&subject=${subject}&publisher=${publisher}&edition=${edition}&year=${year}&page=${newPage}&sortBy=${sortBy}`);
        if (scrollRef.current !== null) {
            (scrollRef.current).scrollTop = 0;
        }
    };

    return (
        <Stack  pt={mobile ? 1 : 0.5}>
            <Stack direction="row" px={5} py={2.75} alignItems="center">
                {query.length > 0 && query !== '2000,2011,2013,2014,2015,2016,2017,2018,2019,2020,2021,2022' ? 
                    <Typography variant="h6" fontWeight={500}>Showing results for: "{query}"</Typography> :
                    <Typography variant="h6" fontWeight={500}>Showing all results</Typography>
                }
                <Box flex={1}/>
                <FormControl sx={{width: 150}}>
                    <InputLabel id="dsortby-select-label">Sort</InputLabel>
                    <Select labelId="sortby-select-label" id="sortby-select" value={sortBy}
                        label="Sort"
                        onChange={handleSortBy}
                        size="small"
                    >
                        <MenuItem value="Default">Default</MenuItem>
                        <MenuItem value="Edition (Low→High)">Edition (Low→High)</MenuItem>
                        <MenuItem value="Edition (High→Low)">Edition (High→Low)</MenuItem>
                        <MenuItem value="Title (A→Z)">Title (A→Z)</MenuItem>
                        <MenuItem value="Title (Z→A)">Title (Z→A)</MenuItem>
                    </Select>
                </FormControl>
            </Stack>
           <Divider />
           <Stack p={3} spacing={4} sx={{overflowY: 'auto', height: 'calc(100vh - 150px)'}} ref={scrollRef}>
            <Box width='100%' display='flex' justifyContent='center'  sx={{ flexGrow: 1}} mt={3}>
                {textbooks.length > 0 ? <ImageList cols={columns} rowHeight={300} gap={30}>
                    {textbooks.map((textbook) => (
                        <TextbookCard key={textbook._id} id={textbook._id} title={textbook.title} year={textbook.year} authors={textbook.authors} edition={textbook.edition} publisher={textbook.publisher} isbn={textbook.isbn} cover={textbook.cover} numChapters={textbook.chapters.length} chapters={textbook.chapters} />
                    )) }
                </ImageList>
                :
                (loading ? <LoadingSpinner text='Loading summaries...'/> :

                <Stack spacing={2} alignItems="center" width='100%' mt={3} mb='40vh'>
                    <Typography variant={mobile ? "h5" : "h4"} fontWeight={700} textAlign='center'>Can't find the textbook you're looking for?</Typography>
                    <Typography variant="h6" fontWeight={400} textAlign='center'>Fill out this form and we will add it to our collection ASAP!</Typography>
                    <Link to="https://forms.gle/ovf8HguL1knEXVfk8" target="_blank" style={{textDecoration: 'none'}}>
                        <Button variant="contained" >Request Form</Button>
                    </Link>
                </Stack> )
            }
            </Box>

            {textbooks.length > 0  && 
            <Box display="flex" justifyContent="center" >
                <Pagination
                    onChange={handlePageChange}
                    page={page}
                    color="primary" 
                    variant = "outlined"
                    shape="rounded" 
                    count={pageCount}
                    defaultPage={1}
                />
            </Box>
            }
           
           </Stack>
           
        </Stack>    
    );
  }
  
  export default SearchScreen;
